import { createRouter, createWebHashHistory } from 'vue-router';

import NoFound from '../views/404.vue'; // 导入404页面组件

import Home from '../views/Home.vue';
import haoka from '../views/Haoka.vue';
import About from '../views/About.vue';
import haoka_history from '../views/Haoka_History.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/haoka',
    name: 'haoka',
    component: haoka
  },
  {
    path: '/haoka_history',
    name: 'haoka_history',
    component: haoka_history
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  // 404路由
  {  
    path: '/404',  
    name: 'NotFound',  
    component: NoFound  
  },  
  {  
    path: '/:catchAll(.*)',  
    redirect: '/404'  
  }  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  // 使用 router 实例的 getRoutes 方法获取所有路由
  const allRoutes = router.getRoutes();
  // 检查用户尝试访问的路径是否在路由配置中定义
  const isDefinedRoute = allRoutes.some(route => route.path === to.path);
  if (to.path !== '/404' && !isDefinedRoute) {
    // 如果用户尝试访问的不是 404 页面，并且路径未定义，导航到 404 页面
    next({ path: '/404' }); // 使用对象形式来确保正确的导航
  } else {
    // 检查用户是否尝试访问限制状态接口
    if (to.path === 'http://127.0.0.1:3000/api/limit-status') {
      try {
        const response = await fetch('http://127.0.0.1:3000/api/limit-status');
        const data = await response.json();
        if (response.ok) {
          next(); // 允许用户跳转
        } else {
          next({ path: '/404' }); // 导航到 404 页面
        }
      } catch (error) {
        console.error('Error fetching limit status:', error);
        next({ path: '/404' }); // 导航到 404 页面
      }
    } else {
      next();
    }
  }
});




export default router;
