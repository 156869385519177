<template>
    <div id="app">
        <v-app>
            <v-container>
                <!-- <h1>卡品列表</h1> -->
                <v-toolbar density="compact">
                    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

                    <v-toolbar-title>卡品列表</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <!-- <v-btn icon>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn> -->

                    <v-tooltip text="夜间模式">
                        <template v-slot:activator="{ props }">
                            <v-btn icon v-bind="props" @click="toggleTheme">
                                <v-icon>{{ themeIcon }}</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>

                    <v-tooltip text="输入密码">
                        <template v-slot:activator="{ props }">
                            <v-btn icon v-bind="props" @click="openInput">
                                <v-icon>mdi-key-variant</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>

                </v-toolbar>

                <br>

                <div class="mb-3">
                    <label class="form-label">这里没有满意的卡？不如看看-> </label>
                    <a href="http://hk.yihao.org.cn/#/?ApiKey=ZJWWBQLV0F3R8N3EAN8CROUUHR3BHC4I"
                        target="_blank">B端商城</a> &nbsp;
                        <br>
                    <label class="form-label">已下架卡品列表 </label>
                    <a href="http://zggso.com/#/haoka_history" target="_blank">已下架卡品列表</a>
                </div>
                <!-- 选择地区、运营商下拉框 -->
                <div class="mb-3">
                    <label for="disableAreaFilter" class="form-label"
                        style="color: red;">选择你的收货地区，否则容易发生驳回:（没有则不选）</label>
                    <v-select v-model="selectedArea" :items="uniqueDisableAreas" item-text="name" item-value="name"
                        label="" clearable @change="filterProducts" variant="outlined" density="compact"></v-select>
                    <v-select v-model="selectedOperator" :items="uniqueOperators" item-text="name" item-value="name"
                        label="" clearable @change="filterProducts" class="mt-3" variant="outlined"
                        density="compact"></v-select>
                </div>

                <div class="mb-3">
                    <p>筛选结果一共有: {{ filteredProducts.length }} 个</p>
                </div>

                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                卡品名称
                            </th>
                            <th class="text-left">
                                运营商
                            </th>
                            <th class="text-left">
                                归属地
                            </th>
                            <th class="text-left">
                                详情
                            </th>
                            <th class="text-left">
                                限制年龄
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, index) in filteredProducts" :key="product.productID"
                            :class="{ 'table-light': index % 2 === 0, 'table-gray': index % 2 !== 0 }">
                            <td><a :href="generateLink(product.productID)" target="_blank">{{ index + 1 }} {{
                            product.productName
                        }}</a>
                            </td>
                            <td>{{ product.operator }}</td>
                            <td>{{ showInfo ? product.area : '***' }}</td>
                            <td><a @click="showProductDetails($event, product.productID)" href="#">详情</a></td>
                            <td>{{ product.age1 }} - {{ product.age2 }}</td>
                        </tr>
                    </tbody>
                </v-table>

                <!-- 详情modal -->
                <v-dialog v-model="dialogVisible" max-width="500" persistent id="productDetailModal">
                    <v-card>
                        <v-card-title class="headline" style="background-color: #448aff; color: aliceblue;">
                            产品详情
                        </v-card-title>
                        <v-card-text>
                            <p>名称: {{ productDetails.title }}</p>
                            <p>套餐: {{ productDetails.package }}</p>
                            <!-- <p>Discount: {{ productDetails.discount }}</p> -->
                            <p>发货物流: {{ productDetails.deliveryMethod }}</p>
                            <p>激活方法: {{ productDetails.activationMethod }}</p>
                            <p style="color: red;">合约期: {{ productDetails.contractPeriod }}</p>
                            <p>停机、注销方式: {{ productDetails.restartAndCancellationMethod }}</p>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="dialogVisible = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 欢迎modal -->
                <v-dialog v-model="welcomeModal" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: #448AFF; color: aliceblue;">
                            公告
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <p>欢迎访问！</p>
                            <br>
                            <p>因为下单后运营商可能驳回，为了避免这种情况，不管在这里下单还是在B端商城下单，切记下单完成后隔天来查单！</p>
                            <a href="https://haokawx.lot-ml.com/Search/Index" target="_blank">本列表查单地址</a>
                            <br><br>
                            <p>如果这里没有合适的卡品，可以访问
                                <a href="http://hk.yihao.org.cn/#/?ApiKey=ZJWWBQLV0F3R8N3EAN8CROUUHR3BHC4I"
                                    target="_blank">B端商城</a>
                                &nbsp;
                                <a href="http://hk.yihao.org.cn/#/pages/order" target="_blank">B端查单</a>
                            </p>
                            <br><br>
                            <p>夜间模式开关在右上角月亮那边</p>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="welcomeModal = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 警告框 -->
                <v-dialog v-model="warningModal" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: red; color: aliceblue;">
                            ？
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <h1>异常行为管控</h1>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="warningModal = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 密码输入器 -->
                <v-dialog v-model="inputPassword" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: orangered; color: aliceblue;">
                            输入密码
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            输入密码以解锁“归属地”显示
                            <div class="mt-5">
                                <v-text-field clearable label="密码" variant="outlined" id="passwordInput" v-model="password" type="password" density="compact"></v-text-field>
                            </div>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="second" text @click="inputPassword = false">点错了</v-btn>
                            <v-btn color="primary" text @click="toggleShowInfo">确认</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-container>
        </v-app>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';
import { useTheme } from 'vuetify';

// 数据
const products = ref([]);
const selectedArea = ref();
const selectedOperator = ref();
const productDetails = ref({});
const scrollPosition = ref(0);
const password = ref(''); 
const showInfo = ref(false);
const dialogVisible = ref(false);
const welcomeModal = ref(true);
const warningModal = ref(false);
const inputPassword = ref(false);

const theme = useTheme();
const themeIcon = ref('mdi-weather-night');


// 计算属性
const uniqueDisableAreas = computed(() => {
    const areas = products.value.flatMap(product => product.disableArea.split(',').map(area => area.trim()));
    return [...new Set(areas)];
});

const uniqueOperators = computed(() => {
    return [...new Set(products.value.map(product => product.operator))];
});

const filteredProducts = computed(() => {
    let filtered = products.value;
    if (selectedArea.value) {
        filtered = filtered.filter(product => !product.disableArea.includes(selectedArea.value));
    }
    if (selectedOperator.value) {
        filtered = filtered.filter(product => product.operator === selectedOperator.value);
    }
    return filtered;
});

// 方法
function toggleTheme() {
    theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
    themeIcon.value = themeIcon.value === 'mdi-weather-sunset-up' ? 'mdi-weather-night' : 'mdi-weather-sunset-up';
}

const showProductDetails = (event, productID) => {
    event.preventDefault();
    scrollPosition.value = window.scrollY;
    getProductDetails(productID);
};

const fetchProducts = async () => {
    try {
        const response = await axios.get('http://zggso.com:3000/api/list');
        // const response = await axios.get('http://127.0.0.1:3000/api/list');
        products.value = response.data.filter(product => product.flag === true);
    } catch (error) {
        console.error('你的行为被管控');
        warningModal.value = true; // 打开模态框
    }
};

const generateLink = (productID) => {
    return `https://haokawx.lot-ml.com/h5order/index?pudID=${productID}&userid=612301`;
};

const getProductDetails = async (productID) => {
    try {
        // const response = await axios.get('http://zggso.com:3000/api/list');
        const response = await axios.get(`http://zggso.com:3000/api/list-detail`);
        const productDetail = response.data.find(item => item.productID === productID);
        if (productDetail) {
            productDetails.value = productDetail;
            dialogVisible.value = true; // 打开模态框
        } else {
            console.error(`Product details not found for product ID ${productID}`);
        }
    } catch (error) {
        console.error('Error fetching product details:', error);
    }
};

const filterProducts = () => {
};

const openInput = () => {
    inputPassword.value = true;
};

const toggleShowInfo = () => {
    if (password.value === String.fromCharCode(49, 57, 49, 51, 49, 53, 52, 53, 50, 57)) {
        showInfo.value = true;
        inputPassword.value = false;
    } else {
        showInfo.value = false;
        selectedArea.value = '';
    }
};


fetchProducts();

</script>

<style>
a {
    text-decoration: none;
}
</style>
