import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'
// import store from './store';
import 'vuetify/styles'; // 引入 Vuetify 样式
import * as components from 'vuetify/lib/components'; // 使用 lib/components 导入组件
import * as directives from 'vuetify/lib/directives'; // 使用 lib/directives 导入指令


const vuetify = createVuetify({
  components,
  directives,
});

createApp(App).use(store).use(router).use(vuetify).mount('#app');
