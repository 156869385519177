<template>  
    <div class="not-found">  
      <h1>404 - 页面不存在</h1>  
      <p>很抱歉，您访问的页面不存在。</p>  
      <p>将在 {{ timeLeft }} 秒后自动返回首页...</p>  
      <router-link to="/">手动返回首页</router-link>  
    </div>  
  </template>  
    
  <script>  
 import { ref, onMounted, onBeforeUnmount } from 'vue';
import router from '@/router'; // 根据你的项目结构调整路径

export default {
  setup() {
    const timeLeft = ref(3); // 初始倒计时时间
    let timer = null; // 用于存储定时器的引用

    // 开始倒计时
    const startCountdown = () => {
      timer = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value--;
        } else {
          clearCountdown();
          // 倒计时结束后导航到主页
          router.push('/');
        }
      }, 1000);
    };

    // 清除倒计时
    const clearCountdown = () => {
      clearInterval(timer);
      timer = null;
    };

    // 组件挂载时开始倒计时
    onMounted(startCountdown);

    // 组件卸载前清除倒计时
    onBeforeUnmount(clearCountdown);

    // 返回响应式的时间剩余
    return {
      timeLeft,
    };
  },
};

  </script>  
    
  <style scoped>  
  .not-found {  
    text-align: center;  
    margin-top: 100px;  
  }  
  h1 {  
    font-size: 24px;  
    margin-bottom: 20px;  
  }  
  p {  
    font-size: 16px;  
    color: #888;  
    margin-bottom: 20px;  
  }  
  router-link {  
    color: #007bff;  
    text-decoration: none;  
  }  
  </style>