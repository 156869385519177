<template>
    <div id="app">
        <v-app>
            <v-container>
                <h1>已下架卡品列表</h1>
                <br>
                <div class="mb-3">
                    <br>
                    <p style="color: red;">本页面展示的商品均为已下架产品，旨在方便用户查询历史订单对应的套餐说明、落地页</p>
                    <p style="color: red;">请不要在此页面任何商品页下单，系统不发货的</p>
                </div>

                <div class="mb-3">
                    <label class="form-label">没有满意的卡？不如看看-> </label>
                    <br>
                    <v-btn variant="outlined" color="blue-accent-2" href="https://haokawx.lot-ml.com/Search/Index" target="_blank">本页面查单地址</v-btn>
                    &nbsp;
                    <v-btn variant="outlined"  color="deep-purple-accent-2" href="http://hk.yihao.org.cn/#/?ApiKey=ZJWWBQLV0F3R8N3EAN8CROUUHR3BHC4I" target="_blank">B端商城(手机访问)</v-btn>
                    &nbsp;
                    <v-btn variant="outlined"  color="deep-purple-accent-2" href="http://hk.yihao.org.cn/#/pages/order" target="_blank">B端查单地址</v-btn>
                </div>



                <!-- 选择地区、运营商 -->
                <div class="mb-3">
                    <br>
                    <!-- <label for="disableAreaFilter" class="form-label" -->
                        <!-- style="color: red;">选择你的收货地区（没有则不选）：</label> -->
                    <v-select v-model="selectedArea" :items="uniqueDisableAreas" item-text="name" item-value="name"
                        clearable @change="filterProducts" variant="outlined" density="compact" label="选择你的收货地区（没有则不选）："></v-select>

                        <!-- <label for="operatorFilter" class="form-label" style="color: green;">选择运营商：</label> -->
                    <v-select v-model="selectedOperator" :items="uniqueOperators" item-text="name" item-value="name"
                        clearable @change="filterProducts" class="mt-3" variant="outlined"
                        density="compact" label="选择运营商："></v-select>

                        <!-- <label for="operatorFilter" class="form-label" style="color: green;">或者直接搜索：</label> -->
                        <v-text-field clearable v-model="searchKeyword"  variant="outlined"  density="compact" label="或者直接搜索："></v-text-field>
                </div>

                <div class="mb-3">
                    <p>筛选结果一共有: {{ filteredProducts.length }} 个</p>
                </div>

                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                序号
                            </th>
                            <th class="text-left">
                                卡品名称
                            </th>
                            <th class="text-left">
                                运营商
                            </th>
                            <th class="text-left">
                                归属地
                            </th>
                            <th class="text-left">
                                详情
                            </th>
                            <th class="text-left">
                                限制年龄
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, index) in filteredProducts" :key="product.productID"
                            :class="{ 'table-light': index % 2 === 0, 'table-gray': index % 2 !== 0 }">
                            <td>{{ index + 1 }}</td>
                            <td><a :href="generateLink(product.productID)" target="_blank">{{ product.productName
                                    }}</a>
                            </td>
                            <td>{{ product.operator }}</td>
                            <td>{{ product.area }}</td>
                            <td><a @click="showProductDetails($event, product.productID)" href="#">详情</a></td>
                            <td>{{ product.age1 }} - {{ product.age2 }}</td>
                        </tr>
                    </tbody>
                </v-table>

                <!-- 详情modal -->
                <v-dialog v-model="dialogVisible" max-width="500" persistent id="productDetailModal">
                    <v-card>
                        <v-card-title class="headline" style="background-color: #448aff; color: aliceblue;">
                            产品详情
                        </v-card-title>
                        <v-card-text>
                            <p>名称: {{ productDetails.title }}</p>
                            <p>套餐: {{ productDetails.package }}</p>
                            <!-- <p>Discount: {{ productDetails.discount }}</p> -->
                            <p>发货物流: {{ productDetails.deliveryMethod }}</p>
                            <p>激活方法: {{ productDetails.activationMethod }}</p>
                            <p style="color: red;">合约期: {{ productDetails.contractPeriod }}</p>
                            <p>停机、注销方式: {{ productDetails.restartAndCancellationMethod }}</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" text @click="dialogVisible = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 欢迎modal -->
                <v-dialog v-model="welcomeModal" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: #E53935; color: aliceblue;">
                            公告
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <p>欢迎访问！</p>
                            <br>
                            <p>这里是历史卡品列表，此处下单均不予受理，请不要尝试下单！</p>
                            <a href="https://haokawx.lot-ml.com/Search/Index" target="_blank">本列表查单地址</a>
                            <br><br>
                            <p>如果这里没有合适的卡品，可以访问
                                <a href="http://hk.yihao.org.cn/#/?ApiKey=ZJWWBQLV0F3R8N3EAN8CROUUHR3BHC4I"
                                    target="_blank">B端商城</a>
                                &nbsp;
                                <a href="http://hk.yihao.org.cn/#/pages/order" target="_blank">B端查单</a>
                            </p>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" text @click="welcomeModal = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 警告框 -->
                <v-dialog v-model="warningModal" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: red; color: aliceblue;">
                            警告
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <h1>你当天请求太多！</h1>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="welcomeModal = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-container>
        </v-app>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';

// 数据
const products = ref([]);
const selectedArea = ref();
const selectedOperator = ref();
const productDetails = ref({});
const scrollPosition = ref(0);
const password = ref('');
const showInfo = ref(false);
const dialogVisible = ref(false);
const welcomeModal = ref(true);
const warningModal = ref(false);
const searchKeyword = ref('');


// 计算属性
const uniqueDisableAreas = computed(() => {
    const areas = products.value.flatMap(product => product.disableArea.split(',').map(area => area.trim()));
    return [...new Set(areas)];
});

const uniqueOperators = computed(() => {
    return [...new Set(products.value.map(product => product.operator))];
});

const filteredProducts = computed(() => {
  let filtered = products.value;
  if (selectedArea.value) {
    filtered = filtered.filter(product => !product.disableArea.includes(selectedArea.value));
  }
  if (selectedOperator.value) {
    filtered = filtered.filter(product => product.operator === selectedOperator.value);
  }
  if (searchKeyword.value) {
    const keyword = searchKeyword.value.trim().toLowerCase();
    filtered = filtered.filter(product => product.productName.toLowerCase().includes(keyword));
  }
  return filtered;
});


// 方法
const showProductDetails = (event, productID) => {
    event.preventDefault();
    scrollPosition.value = window.scrollY;
    getProductDetails(productID);
};

const fetchProducts = async () => {
      try {
       const response = await axios.get('http://zggso.com:3000/api/list-history');
        const allProducts = response.data;
        // 使用 Map 数据结构进行去重
        const uniqueProductsMap = new Map();
        allProducts.forEach(product => {
          // 使用产品 ID 作为 Map 的键，确保唯一性
          uniqueProductsMap.set(product.productID, product);
        });
        // 将 Map 中的值转换为数组，以便进一步处理
        products.value = [...uniqueProductsMap.values()].filter(product => product.flag === false);
      } catch (error) {
        console.error('刷尼玛访问呢', error);
        warningModal.value = true;
      }
    };

const generateLink = (productID) => {
    return `https://haokawx.lot-ml.com/h5order/index?pudID=${productID}&userid=612301`;
};

const getProductDetails = async (productID) => {
    try {
        
        const response = await axios.get(`http://zggso.com:3000/api/list-history-detail`);
        const productDetail = response.data.find(item => item.productID === productID);
        if (productDetail) {
            productDetails.value = productDetail;
            dialogVisible.value = true; // 打开模态框
        } else {
            console.error(`Product details not found for product ID ${productID}`);
        }
    } catch (error) {
        console.error('Error fetching product details:', error);
    }
};

const filterProducts = () => {
};



fetchProducts();

</script>
